import { Component } from '@angular/core';
import { GlobalFields } from '../../GlobalFields';
import { Constants } from '../../Constants';
import { AuthenticationService } from '../../services/AuthenticationService';
import { ModalLogin } from '../../components/login/modalLogin/modalLogin';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Service } from '../../services/Service';
import { PathConstants } from 'src/app/PathConstants';
import { AdminTypeEnum, PermissionsNames } from '../../entities/permissions/permissions';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent {
  GlobalFields = GlobalFields;
  Constants = Constants;
  PathConstants = PathConstants;
  AdminTypeEnum = AdminTypeEnum;

  constructor(
    public service: Service,
    public authService: AuthenticationService,
    public modalCtrl: ModalController,
    public router: Router
  ) {}

  isPageSelected(page: string) {
    return this.router.url.includes(page);
  }

  isSalesDashboard() {
    return !this.router.url.includes('insights') && this.router.url.includes('sales');
  }

  goHome() {
    this.router.navigate(['/' + GlobalFields.currentLanguage]);
  }

  async openLoginModal() {
    const loginModal = await this.modalCtrl.create({
      component: ModalLogin,
      componentProps: {
        borderless: true,
      },
    });

    await loginModal.present();
    const { data } = await loginModal.onWillDismiss();
    if (data) {
    }
  }

  isLoggedIn() {
    return AuthenticationService.isLoggedIn();
  }

  logout() {
    this.authService.logout();
  }

  openSidebar() {
    this.service.openSidebar();
  }

  closeSidebar() {
    this.service.closeSidebar();
  }

	changeRoute(parentRoute: PathConstants) {

		let homeRoute: PathConstants;

		switch (parentRoute) {
			case (PathConstants.adminDashboard):
				homeRoute = this._administrationDashboardRouteByUser();
				break;
			case (PathConstants.supplyDashboard):
				homeRoute = this._supplyDashBoardRouteByUser();
				break;
      case (PathConstants.salesDashboard):
        homeRoute = this._salesDashBoardRouteByUser();
        break;
      case PathConstants.insightsDashboard:
        homeRoute = this._insightsDashboardRouteByUser();
        break;
		}

		this.router.navigate(['/', parentRoute, homeRoute]);
	}

	_administrationDashboardRouteByUser() {
		if (GlobalFields.isSuperAdmin()) {
			return PathConstants.confirmedRequests;
		}
		if (GlobalFields.hasPermission(PermissionsNames.AGENT_DASHBOARD)) {
			return PathConstants.agents;
		}
		return PathConstants.confirmedRequests;
	}

	_supplyDashBoardRouteByUser() {
		if (GlobalFields.isSuperAdmin()) {
			return PathConstants.listings;
		}
		if (GlobalFields.hasPermission(PermissionsNames.PARTNER_DASHBOARD)) {
			return PathConstants.partnerImport;
		}
		return PathConstants.listings;
	}

  _salesDashBoardRouteByUser() {
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SALE ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SALE) {
      return PathConstants.myLeads;
    }

    console.log("fuori");
    // se l'utente è locare la home di sales è sales/locare
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.LOCARE) {
      console.log("dentro");
      return PathConstants.locare;
    }
    return PathConstants.rentRequests;
  }

  _insightsDashboardRouteByUser() {
    if (GlobalFields.isSuperAdmin()) {
      return PathConstants.salesInsights;
    }
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SALE ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SALE) {
      return PathConstants.salesInsights;
    }
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SUPPLY ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SUPPLY) {
      return PathConstants.supplyInsights;
    }

    
    return PathConstants.salesInsights;
  }
}
